import { memo, useState } from "react";

/**
 * add new interface for password input component
 */
interface ComponentProps {
    label?:string,
    value: null | string | undefined,
    onDataChange: (fieldName:string, date: any, obj?: any, objName?: string) => void,
    width?:string,
    errorMessage?: string,
    fieldName?: string,
    disabled?: boolean,
    maxLength?: number,
    showPassword?:boolean,
}

/**
 * New component for password input
 * @param props 
 * @returns 
 */
const InputPasswordField:React.FC<ComponentProps> = (props) => {
    const [, setActualValue] = useState('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setActualValue(event.target.value);
        if (props.onDataChange) {
            props.onDataChange(props.fieldName??"", event.target.value);
        }
    };

    return (
        <div className="sc-hMqMXs jOVrWu">
            <label className="sc-iAyFgw kpsJMx">{props.label}</label>
            <input placeholder="" type={props.showPassword ? "text" : "password"}
                maxLength={props.maxLength}
                className="p-inputtext p-component p-filled sc-hSdWYo OtFod"
                style={{width: props.width}}
                value={props.value??""}
                disabled={props.disabled}
                onChange={handleChange}
                />
            <span className="sc-eHgmQL dGBLpF">{props.errorMessage}</span>
        </div>
    );
}

export default memo(InputPasswordField);