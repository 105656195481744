import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useANAInfo } from "presentation/hook/ANAInfo/useANAInfo";
import { useUserVM } from "presentation/hook/User/useUserVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useChangePasswoTracked } from "presentation/store/ChangePassword/ChangePasswordProvider";
import { memo, useCallback, useMemo, useState } from "react";
import { HPHButton } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarCaption, SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import ChangePasswordForm from "./ChangePasswordForm";

const ChangePasswordFormPanel : React.FC = () => {
    const [ changePasswordState ] = useChangePasswoTracked();
    const messageBarVM = useMessageBarVM();
    const [anaInfoState] = useANAInfoTracked();
    const userVM = useUserVM();
    const [, setIsSave] = useState(false);
    const { anaKeycloak } = useANAInfo();

    const saveButtonClicked = useCallback(async() => {
        setIsSave(true);
        if (!changePasswordState.user) return;

        const currentUser = {
            ...changePasswordState.user,
            name: anaInfoState.userName,
        }
        await userVM.onChangePassword(currentUser).then((data) => {
            if (data && data["ChangePasswordFail"]) {
                const err:string = data["ChangePasswordFail"]?.toString()??"";
                
                messageBarVM.showError(err.replace("Error:", ""));
            } else {
                messageBarVM.showSuccess(data["ChangePasswordSuccess"]?.toString());
                if (anaKeycloak) {
                    anaKeycloak.logout();
                }
            }
        }).catch((error) => {
            messageBarVM.showError(error.message);
        });
    }, [anaInfoState.userName, anaKeycloak, changePasswordState.user, messageBarVM, userVM]);

    const memoChangePasswordForm = useMemo(() => {
        return <ChangePasswordForm/>
    }, [])

    return (
        <div className='side-form-content-left-wrapper' style={{position:'relative', height:"100vh-128px", width:"100%"}}>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>
                            {anaInfoState.userName}
                            <SidebarCaption>{"All fields are required unless indicated as 'optional'"}</SidebarCaption>
                        </SidebarTitle>
                    </Sidebarheader>
                </div>
            </div>
            {memoChangePasswordForm}
            <div>
                <SidebarActionBar style={{width:"300px"}}>
                    <HPHButton label={WorkspaceConstant.Common.BUTTON_CONFIRM} size={'Small'} theme={'Primary'} onClick={saveButtonClicked} />
                </SidebarActionBar>
            </div>
        </div>
    );
}

export default memo(ChangePasswordFormPanel);