import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { memo } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import ChangePasswordFormPanel from "./ChangePasswordFormPanel";

const ChangePasswordComp:React.FC = () => {

    return <div className='main-comp-wrapper'>
        <MessageBarWrapper>        
                <SliderPanel
                isOpen={true}
                leftSectionWidth={'100%'}
                rightSectionWidth={'0%'}
                draggable={true}
                leftChildren={<ChangePasswordFormPanel/>}
                rightChildren={<></>} />
        </MessageBarWrapper>
        </div>
}

export default memo(ChangePasswordComp);