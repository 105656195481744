import { UserConStant, changePasswordRequiredFieldList } from "presentation/constant/User/UserConstant";
import { useUserVM } from "presentation/hook/User/useUserVM";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useChangePasswoTracked } from "presentation/store/ChangePassword/ChangePasswordProvider";
import InputPasswordField from "presentation/view/components/InputPasswordField";
import { useState } from "react";
import { CommonField, FieldType } from "veronica-ui-component/dist/component/core";

const ChangePasswordForm:React.FC = () => {
    const [ changePasswordState ] = useChangePasswoTracked();
    const userVM = useUserVM();
    const [anaInfoState] = useANAInfoTracked();
    const currentUser = changePasswordState.user;
    const USER_CONSTANT = UserConStant.user;
    const allFormState = changePasswordState.allFormState;
    const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
    const [isShowNewPassword, setIsShowNewPassword] = useState<boolean>(false);
    const [isShowRetypePassword, setIsShowRetypePassword] = useState<boolean>(false);

    const showPassword = () => {
        setIsShowPassword(!isShowPassword);
    }

    const showNewPassword = () => {
        setIsShowNewPassword(!isShowNewPassword);
    }

    const showRetryPassword = () => {
        setIsShowRetypePassword(!isShowRetypePassword);
    }

    return (<>
        <div className={'im-flex-row'} style={{marginBottom:'10px'}}>
            <div className={'flex-row-item'}>
                <CommonField
                errorMessages={allFormState}
                isReadOnly={true}
                readOnlyValue={anaInfoState.userName}
                fieldLabel={USER_CONSTANT.USERNAME}
                fieldType={FieldType.TEXT}
                fieldKey={'name'}
                maxLength={30}
                requiredFieldList={changePasswordRequiredFieldList}
                onFieldChange={(e) => {}} />
            </div>
        </div>

        <div className={'im-flex-row'} style={{marginBottom:'10px', columnGap:"5px", alignItems:"flex-end !important"}}>
            <div className={'flex-row-item'}>
                <InputPasswordField
                    width='300px'
                    maxLength={50}
                    label={USER_CONSTANT.OLDPASSWORD}
                    errorMessage={allFormState["password"]}
                    fieldName="password"
                    value={currentUser?.password}                    
                    onDataChange={userVM.onChangePasswordFieldChange}
                    showPassword={isShowPassword}
                />
            </div>
            {!!!isShowPassword && <div className={"password-eye close"} onClick={showPassword}></div>}
            {isShowPassword && <div className={"password-eye open"} onClick={showPassword}></div>}
        </div>
        
        <div className={'im-flex-row'} style={{marginBottom:'10px', columnGap:"5px", alignItems:"flex-end !important"}}>
            <div className={'flex-row-item'}> 
                <InputPasswordField
                    width='300px'
                    maxLength={50}
                    label={USER_CONSTANT.PASSWORD}
                    errorMessage={allFormState["newPassword"]}
                    fieldName="newPassword"
                    value={currentUser?.newPassword}
                    onDataChange={userVM.onChangePasswordFieldChange}
                    showPassword={isShowNewPassword}
                />
            </div>   
            {!!!isShowNewPassword && <div className={"password-eye close"} onClick={showNewPassword}></div>}
            {isShowNewPassword && <div className={"password-eye open"} onClick={showNewPassword}></div>}
        </div>

        <div className={'im-flex-row'} style={{marginBottom:'10px', columnGap:"5px", alignItems:"flex-end !important"}}>
            <div className={'flex-row-item'}>
                <InputPasswordField
                    width='300px'
                    maxLength={50}
                    label={USER_CONSTANT.RETYPEPASSWORD}
                    errorMessage={allFormState["retypePassword"]}
                    fieldName="retypePassword"
                    value={currentUser?.retypePassword}
                    onDataChange={userVM.onChangePasswordFieldChange}
                    showPassword={isShowRetypePassword}
                />
            </div>
            {!!!isShowRetypePassword && <div className={"password-eye close"} onClick={showRetryPassword}></div>}
            {isShowRetypePassword && <div className={"password-eye open"} onClick={showRetryPassword}></div>}
        </div>
    </>);
}

export default ChangePasswordForm;