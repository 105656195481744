import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { ChangePasswordProvider } from "presentation/store/ChangePassword/ChangePasswordProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import ChangePasswordComp from "presentation/view/section/ChangePassword/ChangePasswordComp";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const ChangePasswordContMain = () => {
    return <ANAInfoWrapper permission={Permission.LOGIN}>        
        <ChangePasswordProvider>
            <GridStyles/>
            <ChangePasswordComp/>
        </ChangePasswordProvider>
    </ANAInfoWrapper>
}

export default ChangePasswordContMain;